var UloopjQ = jQuery.noConflict();
var UloopCustomSliderClass;

(function( UloopjQ ){

    window.UloopCustomSliderClass  = UloopCustomSliderClass = function (options) {
        this.defaults = {
            is_amimated_now: false,
            prev_amimation_is_finished: true,
            big_image_show_next_slide_on_click: true,
            big_image_action: function(){},
            use_preloader: true,
            use_single_preload: true,
            use_animate: true,
            use_preview: false,
            use_carousel: false,
            use_preview_mouseOver: false,
            use_auto_scroll: true,//
            use_control_next_prev: false,
            use_auto_load_big_img: false,
            use_move_highlighte_action: false,
            use_image_max_width_height: true,
            use_mobile_pager: false,
            resize_width: true,
            stop_defaut_event: true,
            fancybox: false,
            slider_obj: '',
            count_preview: 4,
            item_autoload: '.slide_data',
            start_auto_on: '.wrap_post',
            active_item_class: 'active',
            active_move_item: '.active_move',
            slider: '.custom_post_image',
            wrap_big_image: '.small_main_image',
            wrap_preview: '.small_preview_wrap',
            mobilePager: '.slider-pager',
            on_error_img: '/img/no-image-small6-no-border.jpg',
            item_preview: 'ul li',
            image_index: '',
            slider_width: 0,
            item_width: 0,
            show_image_index: '',
            prev_button: 'prev',
            next_button: 'next'
        };
        UloopjQ.extend(true, this.defaults, options);
    };

    UloopCustomSliderClass.prototype = {
        SliderInit: function(options){
            var self = this;
            this.options = {};
            var options = this.options = UloopjQ.extend(true, this.defaults, options);

            if(options.stop_defaut_event){
                self.stopTransitionEvent(options);
            }

            options.count_prew_items = UloopjQ(options.slider+' '+options.wrap_preview+' '+options.item_preview).length;

            if(options.fancybox){
                options.big_image_show_next_slide_on_click = false;
                UloopjQ(options.slider+' '+options.wrap_big_image+' .item').attr("data-fancybox", "gallery");
            }

            UloopjQ("body").off("click", options.slider+' '+options.wrap_big_image);
            UloopjQ("body").off("click", options.slider+" "+options.wrap_preview+" "+options.item_preview);
            UloopjQ("body").off("click", options.slider+' .'+options.prev_button+', '+options.slider+' .'+options.next_button);

            UloopjQ(options.slider + ' img').error(function () {
                var error_img = window.imagesDomain + options.on_error_img;

                if(UloopjQ(this).attr('letter')){
                    error_img = 'https://d2hu3w5e3twj7v.cloudfront.net/letters/' + UloopjQ(this).attr('letter') + '-126x101.png';
                } else if (UloopjQ(this).attr('letter-image')){
                    error_img = UloopjQ(this).attr('letter-image');
                } else if (UloopjQ(this).closest('.listing-list-gallery').length && UloopjQ(this).closest('.listing-list-gallery').find('*[letter-image]')) {
                    error_img = UloopjQ(this).closest('.listing-list-gallery').find('*[letter-image]').first().attr('letter-image');
                } else if (UloopjQ(this).parents('.detail_slide_container')) {
                    var slide_index = ~~UloopjQ(this).parents('.item').attr('data-index');
                    UloopjQ('.item[data-index="'+slide_index+'"]', options.slider_obj).remove();
                    if(options.use_preview) {
                        var brocken_preview_obj = UloopjQ(options.wrap_preview + ' ' + '.p_item[data-index="' + slide_index + '"]', options.slider_obj).parents('li');
                        if(UloopjQ(brocken_preview_obj).hasClass('active')){
                            UloopjQ(brocken_preview_obj).next('li').addClass('active');
                        }
                        UloopjQ(brocken_preview_obj).remove();
                    }
                    options.slider_obj = UloopjQ(options.slider);

                    if( UloopjQ(options.slider_obj).find(options.wrap_big_image+' .item').length == 0 ){
                        UloopjQ(options.slider).remove();
                    }
                }

                UloopjQ(this).attr('src', error_img);

                if (UloopjQ(this).parent().css('background-image') != 'none') {
                    var background_size = 'initial';
                    if(UloopjQ(this).parent().hasClass('p_item')){
                        var background_size = 'contain';
                    }
                    UloopjQ(this).parent().css({
                        'background-image': 'url(' + error_img + ')',
                        'background-size': background_size,
                        '-webkit-background-size': background_size,
                    });
                }
            });

            if(options.use_preloader){
                self.preloadImages(options);
            }

            if(options.use_auto_load_big_img){
                self.loadBigImages(options);
            }

            if(options.use_preview && options.count_prew_items == options.count_preview){
                UloopjQ(options.slider+' #carouselWrap').addClass("alldisable");
            }
            else if(options.use_preview && options.count_prew_items < options.count_preview){
                UloopjQ(options.slider+' #carouselWrap').addClass("allshow");
            }

            if (options.big_image_show_next_slide_on_click) {
                self.actionOnMainImage(options);
            }

            UloopjQ("body").on("click", options.slider+' '+options.wrap_big_image + ' .item', function(){
              options.big_image_action(this);
            });

            if(options.use_auto_scroll){
                self.autoScroll(options);
            }

            if (options.use_carousel){
                self.dragCarousel(options);
            }

            if (typeof(SwipeInit) != 'undefined') {
                self.swipeAction(options);
            }

            if(options.use_mobile_pager){
                self.buildMobilePager(options);
            }

            if(options.resize_width){
                self.resizeWidth(options);

                UloopjQ(window).on('resize',function(){
                    self.resizeWidth(options);
                });
            }

            if(UloopjQ(options.slider+' .'+options.prev_button).length){
                UloopjQ("body").on("click", options.slider+' .'+options.prev_button+', '+options.slider+' .'+options.next_button, function (){

                    if(options.is_amimated_now || UloopjQ(this).parents('.alldisable').length){
                        return;
                    }

                    options.slider_obj = UloopjQ(this).parents(options.slider);

                    if(typeof( UloopjQ(this).parents(options.slider).attr('ajax')) !== 'undefined'){
                        if(UloopjQ(this).hasClass(options.prev_button)){
                            self.ajaxLoad(options, 'prev');
                        }
                        else if(UloopjQ(this).hasClass(options.next_button)){
                            self.ajaxLoad(options, 'next');
                        }
                        return;
                    }

                    // if(UloopjQ(options.slider_obj).find('.item').length <= 1){
                    //     options.use_animate = false;
                    //     return;
                    // }
                    // else{
                    //     options.use_animate = true;
                    // }

                    var active_preview = UloopjQ(options.slider_obj).find("."+options.active_item_class).index();

                    if(options.use_preview && active_preview+1 == options.count_prew_items && UloopjQ(this).hasClass(options.next_button)){
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":first").click();
                    }
                    else if(options.use_preview && active_preview == 0 && options.count_prew_items <= options.count_preview && UloopjQ(this).hasClass(options.prev_button)){
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":last").click();
                    }
                    else
                    {
                        if(UloopjQ(this).hasClass(options.prev_button)){
                            self.prevSlide(options, false);
                        }
                        else if(UloopjQ(this).hasClass(options.next_button)){
                            self.nextSlide(options, false);
                        }
                    }
                });



            }


            if(options.use_preview){

                if (options.use_preview_mouseOver){
                    self.preview_mouseOver(options);
                }

                if(options.use_move_highlighte_action){
                    self.mouseActiveHighlighte(options);
                }

                UloopjQ("body").on("click", options.slider+" "+options.wrap_preview+" "+options.item_preview, function ()
                {
                    if(options.is_amimated_now){
                        return;
                    }
                    options.current_preview = this;
                    options.slider_obj = UloopjQ(options.current_preview).parents(options.slider);

                    if(options.count_prew_items <= options.count_preview){
                        options.use_animate = false;
                    }
                    else{
                        options.use_animate = true;
                    }

                    if(UloopjQ(options.current_preview).index() > UloopjQ(options.slider_obj).find("."+options.active_item_class).index()){
                        self.nextSlide(options, true);
                    }
                    else if(UloopjQ(options.current_preview).index() == UloopjQ(options.slider_obj).find("."+options.active_item_class).index() && UloopjQ(options.slider_obj).find("."+options.active_item_class).index() == 0 && options.count_prew_items > options.count_preview){
                        self.prevSlide(options, false);
                    }
                    else if(UloopjQ(options.current_preview).index() < UloopjQ(options.slider_obj).find("."+options.active_item_class).index()){;
                        self.prevSlide(options, true);
                    }
                });
            }

        },
        stopTransitionEvent: function(options){
            UloopjQ("body").on("click", options.wrap_preview+" "+options.item_preview+" a, "+options.wrap_big_image+" a", function(e){
                e.preventDefault();
            });
        },
        showImgByIndex: function(elem,options,img_index){
            if(options.is_amimated_now){
                return;
            }
            var self_obj = UloopjQ(options.slider);

            UloopjQ(options.slider).find("div.slide_data").each(function(index,elem){
                if(UloopjQ(elem).attr("data-index")==img_index){
                    UloopjQ(options.image_index).html(img_index);
                    return false;
                }

                UloopjQ(options.slider).find(options.wrap_big_image).append(UloopjQ(options.slider).find(options.wrap_big_image+" div.slide_data:first"));

            });


            if(options.use_preview){
                var active_item_preview = UloopjQ(self_obj).find(options.wrap_preview+' '+options.item_preview+'.active').clone();
                UloopjQ(self_obj).find(options.wrap_preview+' '+options.item_preview+'.active').remove();

                UloopjQ(self_obj).find(options.wrap_preview+" ul").prepend(active_item_preview);
            }



        },
        ajaxLoad:function(options, type){
            var current_slider = this;
            var use_background = false;
            var slides = UloopjQ(options.slider_obj);
            var slides_item = UloopjQ(options.slider_obj).find('.item').clone().html('');
            var slides_wrap = UloopjQ(options.slider_obj).find(options.wrap_big_image);
            if(UloopjQ(slides_item).hasClass('background')){
                use_background = true;
            }

            UloopjQ('<div class="slider_preloader"><img src="/img/loader_16x16.gif"></div>').appendTo(slides_wrap);

            var action = UloopjQ(options.slider_obj).attr('ajax');
            var data_id = UloopjQ(options.slider_obj).attr('data-id');
            var image_index = 2;

            UloopjQ(slides).removeAttr('ajax');

            UloopjQ.ajax({
                url: 'ajax_content.php',
                data: 'action='+action+'&data_id='+data_id+'&size='+UloopjQ(slides).attr('size')+'&data_width='+UloopjQ(slides).attr('data-width')+'&data_height='+UloopjQ(slides).attr('data-height')+'&data_start='+(typeof UloopjQ(slides).attr('data-start') != 'undefined' ? UloopjQ(slides).attr('data-start') : 2),
                type: "POST",
                async: true,
                dataType: "json",
                success: function(data){
                    for (key in data)
                    {

                        var new_item = UloopjQ( slides_item ).clone().attr({'data-index':image_index, 'data-url':data[key]}).addClass('wait');
                        if(use_background){
                            UloopjQ(new_item).css({'background-image':''});
                        }

                        UloopjQ(new_item).appendTo( slides_wrap );

                        image_index++;

                    }
                },
                complete:function(data) {
                    current_slider.preloadImages(options);
                    UloopjQ('.slider_preloader', slides).remove();

                    if(type == 'next'){
                        current_slider.nextSlide(options, false);
                    }
                    else if(type == 'prev'){
                        current_slider.prevSlide(options, false);
                    }
                }
            });

        },
        showImgIndex: function(options){
            var data_index = UloopjQ(options.slider_obj).find(options.wrap_big_image).find(".slide_data:first").attr("data-index");
            if(options.image_index && UloopjQ(options.image_index).length && data_index){
                UloopjQ(options.image_index).html(data_index);
            }
            else if(options.use_mobile_pager && typeof(UloopjQ(options.mobilePager))!= 'undefined' && data_index){
                UloopjQ(options.slider_obj).find(options.mobilePager+' li').removeClass('act');
                UloopjQ(options.slider_obj).find(options.mobilePager+' li[rel="sp_index_'+data_index+'"]').addClass('act');
            }
        },
        dragCarousel: function(options) {
            UloopjQ("body").on('mousemove', options.wrap_preview, function(e) {
                options.slider_obj = UloopjQ(this).parents(options.slider);
                var thisX = e.pageX - UloopjQ(this).width() / 2;

                UloopjQ(this).offset({
                    left: thisX
                });
            });
        },
        preview_mouseOver: function(options) {
            var over_preview = this;
            var mouseenter = false;

            if(options.use_auto_scroll && mouseenter){
                setTimeout(function()
                {
                    over_preview.nextSlide(options);
                }, 2000);
            }

            UloopjQ("body").on("mouseenter", options.wrap_preview+" "+options.item_preview, function ()
            {
                var mouseenter = true;

                options.current_preview = this;
                var current_item_class = UloopjQ(options.current_preview).attr('class');

                if(current_item_class == options.active_item_class)
                    return;

                options.slider_obj = UloopjQ(options.current_preview).parents(options.slider);

                if(UloopjQ(options.current_preview).index() > UloopjQ(options.slider_obj).find("."+options.active_item_class).index()){
                    over_preview.nextSlide(options, true);
                }else if(UloopjQ(options.current_preview).index() <= UloopjQ(options.slider_obj).find("."+options.active_item_class).index()){;
                    over_preview.prevSlide(options, true);
                }else{
                    options.is_amimated_now = false;
                }

                UloopjQ(options.wrap_preview+" "+options.item_preview+" "+options.active_item_class).mouseenter();

            });

            UloopjQ("body").on("mouseleave", options.wrap_preview+" "+options.item_preview, function ()
            {
                mouseenter = false;
            });
        },
        swipeAction: function(options){
            var current_big_img = this;
            var slides = UloopjQ(options.slider+' '+options.wrap_big_image);
            var active = slides.filter('.active');
            var i = slides.index(active);
            var width = UloopjQ(options.slider).width();

//            slides.on("swiperight", function(e) {
//                if(options.is_amimated_now){
//                    return;
//                }
//
//                options.slider_obj = UloopjQ(e.currentTarget).parents(options.slider);
//                if(UloopjQ(options.slider_obj).find(options.item_preview).length <= options.count_preview){
//                        options.use_animate = false;
//                }
//                else{
//                        options.use_animate = true;
//                }
//
//                current_big_img.prevSlide(options, false);
//            });
//
//            slides.on("swipeleft", function(e) {
//                if(options.is_amimated_now){
//                    return;
//                }
//
//                options.slider_obj = UloopjQ(e.currentTarget).parents(options.slider);
//                if(UloopjQ(options.slider_obj).find(options.item_preview).length <= options.count_preview){
//                        options.use_animate = false;
//                }
//                else{
//                        options.use_animate = true;
//                }
//
//                current_big_img.nextSlide(options, false);
//            });



            slides.on("movestart", function(e) {
                if(options.is_amimated_now){
                    return;
                }

                if (
                    (e.distX > e.distY && e.distX < -e.distY) ||
                    (e.distX < e.distY && e.distX > -e.distY) ||
                    (e.distY>6) ||
                    (e.distY<-6)
                ) {
                    e.preventDefault();
                    return;
                }

                options.slider_obj = UloopjQ(e.currentTarget).parents(options.slider);
                var cur_slides = UloopjQ(options.slider_obj).find(options.wrap_big_image).get(0);

                if(typeof( UloopjQ(this).parents(options.slider).attr('ajax') ) !== 'undefined'){
                    if(e.distX > 0){
                        current_big_img.ajaxLoad(options, 'prev');
                    }
                    else if(e.distX < 0){
                        current_big_img.ajaxLoad(options, 'next');
                    }
                    return;
                }

                var active_preview = UloopjQ(options.slider_obj).find("."+options.active_item_class).index();
                if(options.use_preview && active_preview+1 == options.count_prew_items && e.distX < 0){
                    UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":first").click();
                }
                else if(options.use_preview && active_preview == 0 && options.count_prew_items <= options.count_preview && e.distX > 0){
                    UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":last").click();
                }
                else {
                    if (e.distX < 0) {
                        current_big_img.nextSlide(options, false);
                    }
                    if (e.distX > 0) {
                        current_big_img.prevSlide(options, false);
                    }
                }
            });

//
//            slides.on("move", function(e) {
//                if(options.is_amimated_now){
//                    return;
//                }
//
//                options.slider_obj = UloopjQ(e.currentTarget).parents(options.slider);
//                var cur_slides = UloopjQ(options.slider_obj).find(options.wrap_big_image).get(0);
//
//                var left = 100 * e.distX / width;
//                // Move slides with the finger
//                if (e.distX < 0) {
//                        cur_slides.style.marginLeft = (left+100)+'%';
//                }
//                if (e.distX > 0) {
//                        cur_slides.style.marginLeft = (left-100)+'%';
//                }
//            });
//
//
//            slides.on('movestart', function(e) {
//                    options.slider_obj = UloopjQ(e.currentTarget).parents(options.slider);
//
//                    if ((e.distX > e.distY && e.distX < -e.distY) ||
//                        (e.distX < e.distY && e.distX > -e.distY)) {
//                            e.currentTarget.preventDefault();
//                            return;
//                    }
//
////                    wrap.addClass('notransition');
//            });
//
//
//            slides.on('moveend', function(e) {
//                    //wrap.removeClass('notransition');
//
//                    options.slider_obj = UloopjQ(e.currentTarget).parents(options.slider);
//                    var cur_slides = UloopjQ(options.slider_obj).find(options.wrap_big_image).get(0);
//
//                    cur_slides.style.marginLeft = '';
//
//            });

        },
        preloadImages: function(options){
            var sliders = UloopjQ(options.slider);
            UloopjQ(sliders).find(options.item_autoload+'.wait').each(function(index,elem){
                if(typeof(UloopjQ(elem).attr("data-url")) === 'undefined')
                    return;

                UloopjQ("<img>").addClass("preload-image").attr({"src":UloopjQ(elem).attr("data-url"),"data-index":UloopjQ(elem).attr("data-index")}).one("load", function() {
                    var img_width = UloopjQ(elem).attr("data-width");
                    var img_height = UloopjQ(elem).attr("data-height");
                    var img_alt = UloopjQ(elem).attr("data-alt");

                    UloopjQ(elem).html('<img draggable="false" '+(typeof(img_alt)!='undefined' ? 'alt="'+img_alt+'"' : '')+' '+(typeof(img_width)!='undefined' ? 'width="'+img_width+'"' : '')+' '+(typeof(img_height)!='undefined' ? 'height="'+img_height+'"' : '')+' src="'+UloopjQ(this).attr("src")+'">');

                    if(options.use_image_max_width_height) {
                        UloopjQ('img', elem).css({
                            "max-width": options.item_width,
                        });
                    }

                    if(UloopjQ(elem).hasClass('background')){
                        UloopjQ(elem).css({'background-image':'url("'+UloopjQ(this).attr("src")+'")'});
                    }
                    UloopjQ(elem).fadeIn(700, function(){
                        UloopjQ(elem).removeClass('wait');
                    })
                }).each(function() {
                    if(this.complete) {UloopjQ(this).load();}
                }).one("error", function(e) {
                    if(UloopjQ(elem).hasClass('video')){
                        var no_image_url = window.imagesDomain + '/img/no-video-preview2.jpg';
                    }
                    else{
                        var no_image_url = window.imagesDomain + options.on_error_img;
                        if(UloopjQ(elem).attr('letter')){
                            no_image_url = 'https://d2hu3w5e3twj7v.cloudfront.net/letters/' + UloopjQ(elem).attr('letter') + '-126x101.png';
                        } else if (UloopjQ(elem).attr('letter-image')){
                            no_image_url = UloopjQ(elem).attr('letter-image');
                        } else if (UloopjQ(elem).closest('.listing-list-gallery').length &&  UloopjQ(elem).closest('.listing-list-gallery').find('*[letter-image]')) {
                            no_image_url = UloopjQ(elem).closest('.listing-list-gallery').find('*[letter-image]').attr('letter-image');
                        } else if (UloopjQ(elem).parents('.detail_slide_container')) {
                            var slide_index = ~~UloopjQ(elem).attr('data-index');
                            UloopjQ('.item[data-index="'+slide_index+'"]', options.slider_obj).remove();

                            if(options.use_preview) {
                                var brocken_preview_obj = UloopjQ(options.wrap_preview+' '+'.p_item[data-index="'+slide_index+'"]', options.slider_obj).parents('li');
                                if(UloopjQ(brocken_preview_obj).hasClass('active')){
                                    UloopjQ(brocken_preview_obj).next('li').addClass('active');
                                }
                                UloopjQ(brocken_preview_obj).remove();
                            }
                            options.slider_obj = UloopjQ(options.slider);

                            if( UloopjQ(options.slider_obj).find(options.wrap_big_image+' .item').length == 0 ){
                                UloopjQ(options.slider).remove();
                            }
                        }
                    }

                    UloopjQ(elem).html('<img draggable="false" src="' + no_image_url + '" style="max-width:; max-height:;">');
                    if (UloopjQ(elem).hasClass('background')) {
                        UloopjQ(elem).css({
                            'background-image': 'url(' + no_image_url + ')',
                            'background-size': 'initial',
                            '-webkit-background-size': 'initial',
                        });
                    }
                    UloopjQ(elem).removeClass('wait').fadeIn(700);
                });
            });
        },
        loadBigImages: function(options){
//            onResourcesAndDOMReady(function(){
//                var sliders = UloopjQ(options.slider);
//                UloopjQ(sliders).find(options.wrap_big_image+' a').each(function(index,elem){
//                    if(UloopjQ('img',elem).length<=0){
//                        UloopjQ(elem).html('<img src="'+UloopjQ(elem).attr("data-src")+'">');
//                    }
//                });
//            });
        },
        buildMobilePager: function(options){//options.wrap_big_image
            var sliders = UloopjQ(options.slider);

            UloopjQ(sliders).each(function(index,elem){
                var countMobilePager = ~~UloopjQ(elem).find(options.wrap_big_image).attr('data-pages-index');
                if(!countMobilePager)
                    return;

                var sliderPager = document.createElement('ul');
                sliderPager.className = 'slider-pager';

                for(var i=1; i<=countMobilePager; i++){
                    var sliderPagerLi = document.createElement('li');
                    if(i==1)
                        sliderPagerLi.className = 'act';
                    sliderPagerLi.setAttribute('rel','sp_index_'+i);
                    sliderPager.appendChild(sliderPagerLi);
                }

                UloopjQ(elem).append(sliderPager);
            });
        },
        resizeWidth: function(options){

            options.item_width = 0;
            let sliders = document.querySelectorAll(options.slider);
            sliders.forEach(function(slider, index){
                const item = slider.querySelector('.item');
                if(options.use_control_next_prev){
                    if (item) {
                        slider.querySelectorAll('.' + options.prev_button + ', .' + options.next_button).forEach(function (element) {
                            element.style.display = 'none';
                        });
                    }
                    else{
                        slider.querySelectorAll('.' + options.prev_button + ', .' + options.next_button).forEach(function (element) {
                            element.style.display = '';
                        });
                    }
                }

                if (!options.item_width) {
                    options.item_width = slider.offsetWidth;//parseFloat(getComputedStyle(slider, null).width.replace("px", ""));
                }

                if (item) {
                    if (!item.classList.contains('static_width')) {
                        item.style.width = `${options.item_width}px`;
                        item.style.minWidth = `${options.item_width}px`;

                        if (options.use_image_max_width_height) {
                            let imgElement = slider.querySelector('.item img');
                            if (imgElement) {
                                imgElement.style.maxWidth = `${options.item_width}px`;
                            }
                        }
                    }
                }
            });

            // UloopjQ(options.slider).each(function(index,elem){
            //     if(options.use_control_next_prev){
            //         if(UloopjQ('.item', elem).length <= 1){
            //             UloopjQ('.'+options.prev_button+', .'+options.next_button, elem).hide();
            //         }
            //         else{
            //             UloopjQ('.'+options.prev_button+', .'+options.next_button, elem).show();
            //         }
            //     }
            //
            //     options.item_width = UloopjQ(elem).width();
            //
            //     UloopjQ(".item", elem).width(options.item_width).css('min-width', options.item_width);
            //
            //     if(options.use_image_max_width_height) {
            //         UloopjQ('.item img', elem).css({
            //             "max-width": options.item_width,
            //         });
            //     }
            // });
        },
        actionOnMainImage: function(options){
            var current_big_img = this;

            UloopjQ("body").on("click", options.slider+' '+options.wrap_big_image, function(){

                var data_url = UloopjQ(options.slider+' '+options.wrap_big_image).find('.slide_data').first().find('img').attr('src');

                // if (data_url.indexOf('150782931382') != -1) {
                if (data_url && data_url.indexOf('b38f0f47c9f54333f0307b260a223736') != -1) {
                    return;
                }


                if(options.is_amimated_now){
                    return;
                }

                options.slider_obj = UloopjQ(this).parents(options.slider);

                if(typeof( UloopjQ(this).parents(options.slider).attr('ajax')) !== 'undefined'){
                    current_big_img.ajaxLoad(options, 'next');
                    return;
                } else if(UloopjQ(options.slider_obj).find('.item').length <= 1){
                    // return false;
                }

                if(UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length <= options.count_preview){
                    options.use_animate = false;
                }
                else{
                    options.use_animate = true;
                }

                var active_preview = UloopjQ(options.slider_obj).find("."+options.active_item_class).index();
                if(options.use_preview && active_preview+1 == options.count_prew_items){
                    UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":first").click();
                }
                else{
                    if( (UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length > UloopjQ(options.slider_obj).find("."+options.active_item_class).index()+1) ||
                        !options.use_preview ){
                        current_big_img.nextSlide(options, false);
                    }else{
                        current_big_img.prevSlide(options, false);
                    }
                }
            });
        },
        mouseActiveHighlighte: function(options){
            var current_row = this;
            var start_active_position = 0;
            UloopjQ("body").on("mouseenter", options.slider+" "+options.wrap_preview+' '+options.item_preview, function ()
            {
                options.slider_obj = UloopjQ(this).parents(options.slider);

                var activeItem = UloopjQ(options.slider_obj).find(options.active_move_item);
                if(!start_active_position){
                    start_active_position = UloopjQ(activeItem).position().left;
                }
                UloopjQ(options.slider_obj).find(options.active_move_item).animate({
                    marginLeft : UloopjQ(this).position().left
                },200);
            });
        },
        autoScroll: function(options){
            var current_row = this;
            var auto_scrolling = true;
            var intervalID = '';
            var checkAnimateInervalID = '';
            var slider = '';
            UloopjQ("body").on("mouseenter", options.slider, function ()
            {
                auto_scrolling = false;
            });
            UloopjQ("body").on("mouseleave", options.slider, function ()
            {
                auto_scrolling = true;
            });
            UloopjQ("body").on("mouseenter", options.start_auto_on, function ()
            {
                slider = UloopjQ(this).find(options.slider);
                if(options.is_amimated_now){
                    checkAnimateInervalID=setInterval( function() {
                        if(!options.is_amimated_now){
                            options.slider_obj = slider;
                            clearInterval(checkAnimateInervalID);
                        }
                    } , 1000);
                }
                else{
                    options.slider_obj = slider;
                }

                clearInterval(intervalID);
                intervalID=setInterval( function() {
                    if(auto_scrolling && !options.is_amimated_now){
                        if(UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length <= options.count_preview){
                            options.use_animate = false;
                        }
                        else{
                            options.use_animate = true;
                        }


                        if(UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length > UloopjQ(options.slider_obj).find("."+options.active_item_class).index()+1){
                            current_row.nextSlide(options, false);
                        }
                        else{
                            current_row.prevSlide(options, false);
                        }
                    }
                } , 3000);
            });
            UloopjQ("body").on("mouseleave", options.start_auto_on, function ()
            {
                clearInterval(intervalID);
                clearInterval(checkAnimateInervalID);
            });
        },
        nextSlide: function (options, choise) {
            if(!options.is_amimated_now){
                var self_obj = this;
                options.is_amimated_now = true;
                var old_slider = options.slider_obj;
                var use_background = false;
                if(UloopjQ(options.slider_obj).find('.item').hasClass('background')){
                    use_background = true;
                }


                if(options.use_preview){
                    if(choise){
                        if(typeof(UloopjQ(options.current_preview).find(".p_item").attr("data-src")) != 'undefined'){
                            var bigImgLink = UloopjQ(options.current_preview).find(".p_item").attr("data-src");
                        } else {
                            var bigImgLink = UloopjQ(options.current_preview).find(".p_item").attr("href");
                        }
                        var bigImgIndex = UloopjQ(options.current_preview).find(".p_item").attr("data-index");
                        var alt = UloopjQ(options.current_preview).find(".p_item").attr("data-alt");
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview).removeClass(options.active_item_class);
                        UloopjQ(options.current_preview).addClass(options.active_item_class);
                    }
                    else{
                        var nextImg = UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+"."+options.active_item_class).next();
                        if(typeof(UloopjQ(nextImg).find(".p_item").attr("data-src")) != 'undefined'){
                            var bigImgLink = UloopjQ(nextImg).find(".p_item").attr("data-src");
                        } else {
                            var bigImgLink = UloopjQ(nextImg).find(".p_item").attr("href");
                        }
                        var bigImgIndex = UloopjQ(nextImg).find(".p_item").attr("data-index");
                        var alt = UloopjQ(nextImg).find(".p_item").attr("data-alt");
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview).removeClass(options.active_item_class);
                        UloopjQ(nextImg).addClass(options.active_item_class);

                    }

                    if(UloopjQ(options.slider_obj).find(options.wrap_big_image+' .item img').attr('src') == bigImgLink &&  options.count_prew_items <= options.count_preview){
                        options.is_amimated_now = false;
                        return;
                    }

                    if(options.use_single_preload){
                        UloopjQ(options.slider_obj).find(options.wrap_big_image+' .item:first').after('<div class="item slide_data wait' + (use_background ? ' background' : '') + '" data-alt="'+alt+'" data-index="'+bigImgIndex+'" data-url="'+bigImgLink+'" data-src="'+bigImgLink+'" ' + (options.fancybox ? 'data-fancybox="gallery"' : '') + ' style="width: '+~~options.item_width+'px; min-width: '+~~options.item_width+'px;"><img alt="'+alt+'" src="'+bigImgLink+'"></div>');
                    }
                    else{
                        UloopjQ(options.slider_obj).find(options.wrap_big_image+' .item:first').after('<div class="item slide_data wait" data-index="'+bigImgIndex+'" data-alt="'+alt+'" data-index="'+bigImgIndex+'" data-url="'+bigImgLink+'" data-src="'+bigImgLink+'" ' + (options.fancybox ? 'data-fancybox="gallery"' : '') + ' style="width: '+~~options.item_width+'px; min-width: '+~~options.item_width+'px;"></div>');
                    }


                }
                else{
                    var firstA = UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item:first");
                    UloopjQ(options.slider_obj).find(options.wrap_big_image).append(firstA.clone());
                }

                self_obj.preloadImages(options);

                UloopjQ(options.slider_obj).find(options.wrap_big_image).animate({
                    marginLeft : -UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item").outerWidth(true)
                },320, function(){
                    if(UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item").length <= 1){
                        options.is_amimated_now = false;
                        return;
                    }
                    UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item:first").remove();
                    UloopjQ(options.slider_obj).find(options.wrap_big_image).css("marginLeft", "0px");

                    self_obj.preloadImages(options);
                    self_obj.showImgIndex(options);
                    options.is_amimated_now = false;
                });


                if(options.use_preview){
                    if(!options.use_animate || options.count_prew_items <= options.count_preview){
                        var activeItem = UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+"."+options.active_item_class);
                        var margin_left = UloopjQ(activeItem).position().left;
                        UloopjQ(options.slider_obj).find(options.active_move_item).css({'margin-left':margin_left});
                    }
                    else if(options.use_animate){
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" ul").append('<li>'+UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":first").html()+'</li>');

                        UloopjQ(options.slider_obj).find(options.wrap_preview).animate({
                            marginLeft : -UloopjQ(options.wrap_preview+" "+options.item_preview).outerWidth(true)
                        },300, function(){

                            var activeItem = UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+"."+options.active_item_class);

                            UloopjQ(options.slider_obj).find(options.active_move_item).animate({
                                marginLeft : UloopjQ(activeItem).position().left - UloopjQ(activeItem).outerWidth(true)
                            },200);

                            UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":first").remove();
                            UloopjQ(options.slider_obj).find(options.wrap_preview).css("marginLeft", "0px");
                        });
                    }
                }
            }
        },
        prevSlide: function (options, choise){
            if(!options.is_amimated_now && UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length != 1){
                var self_obj = this;
                options.is_amimated_now = true;
                var use_background = false;
                if(UloopjQ(options.slider_obj).find('.item').hasClass('background')){
                    use_background = true;
                }

                if(options.use_preview){
                    if(choise){
                        if(typeof(UloopjQ(options.current_preview).find(".p_item").attr("data-src")) != 'undefined'){
                            var bigImgLink = UloopjQ(options.current_preview).find(".p_item").attr("data-src");
                        } else {
                            var bigImgLink = UloopjQ(options.current_preview).find(".p_item").attr("href");
                        }
                        var bigImgIndex = UloopjQ(options.current_preview).find(".p_item").attr("data-index");
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview).removeClass(options.active_item_class);
                        UloopjQ(options.current_preview).addClass(options.active_item_class);
                    }
                    else{
                        if(UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length > options.count_preview){
                            var prevImg = UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":last");
                        }
                        else{
                            var prevImg = UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+"."+options.active_item_class).prev();
                        }
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview).removeClass(options.active_item_class);
                        if(typeof(UloopjQ(prevImg).find(".p_item").attr("data-src")) != 'undefined'){
                            var bigImgLink = UloopjQ(prevImg).find(".p_item").attr("data-src");
                        } else {
                            var bigImgLink = UloopjQ(prevImg).find(".p_item").attr("href");
                        }
                        var bigImgIndex = UloopjQ(prevImg).find(".p_item").attr("data-index");

                        if(UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length <= options.count_preview){
                            UloopjQ(prevImg).addClass(options.active_item_class);
                        }
                    }

                    if(UloopjQ(options.slider_obj).find(options.wrap_big_image+' .item img').attr('src') == bigImgLink && UloopjQ(options.slider_obj).find(options.wrap_preview+' '+options.item_preview).length <= options.count_preview){
                        options.is_amimated_now = false;
                        return;
                    }

                    if (options.use_single_preload) {
                        UloopjQ(options.slider_obj).find(options.wrap_big_image + " .item:first").before('<div class="item slide_data wait' + (use_background ? ' background' : '') + '" data-url="' + bigImgLink + '" data-src="' + bigImgLink + '" data-index="' + bigImgIndex + '" ' + (options.fancybox ? 'data-fancybox="gallery"' : '') + ' style="width: ' + ~~options.item_width + 'px; min-width: ' + ~~options.item_width + 'px;"><img src="' + bigImgLink + '"></div>');
                    }
                    else {
                        UloopjQ(options.slider_obj).find(options.wrap_big_image + " .item:first").before('<div class="item slide_data wait' + (use_background ? ' background' : '') + '" data-url="' + bigImgLink + '" data-src="' + bigImgLink + '" data-index="' + bigImgIndex + '" ' + (options.fancybox ? 'data-fancybox="gallery"' : '') + ' style="width: ' + ~~options.item_width + 'px; min-width: ' + ~~options.item_width + 'px;"></div>');
                    }
                }
                else{
                    var lastA = UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item:last");
                    UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item:first").before(UloopjQ(lastA).clone());
                }

                var bigImgMarginL = parseInt(UloopjQ(options.slider_obj).find(options.wrap_big_image).css("marginLeft"));

                UloopjQ(options.slider_obj).find(options.wrap_big_image).css("marginLeft", -UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item:last").outerWidth(true));

                self_obj.preloadImages(options);

                UloopjQ(options.slider_obj).find(options.wrap_big_image).animate({
                    marginLeft : bigImgMarginL
                },320, function(){
                    if(UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item").length <= 1){
                        options.is_amimated_now = false;
                        return;
                    }

                    UloopjQ(options.slider_obj).find(options.wrap_big_image+" .item:last").remove();

                    self_obj.preloadImages(options);
                    self_obj.showImgIndex(options);

                    options.is_amimated_now = false;
                });

                if(options.use_preview){
                    if(!options.use_animate || options.count_prew_items <= options.count_preview){
                        UloopjQ(options.slider_obj).find(options.active_move_item).css({'margin-left':UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+"."+options.active_item_class).position().left});
                    }
                    else if(options.use_animate){
                        UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":first").before('<li>'+UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":last").html()+'</li>');

                        if(!choise){
                            UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":first").addClass(options.active_item_class);
                        }

                        var marginL = parseInt(UloopjQ(options.slider_obj).find(options.wrap_preview).css("marginLeft"));

                        UloopjQ(options.slider_obj).find(options.wrap_preview).css("marginLeft", -UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview).outerWidth(true));

                        UloopjQ(options.slider_obj).find(options.wrap_preview).animate({
                            marginLeft : marginL
                        },300, function(){

                            var activeItem = UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+"."+options.active_item_class);
                            UloopjQ(options.slider_obj).find(options.active_move_item).animate({
                                marginLeft : UloopjQ(activeItem).position().left
                            },200);

                            UloopjQ(options.slider_obj).find(options.wrap_preview+" "+options.item_preview+":last").remove();
                        });
                    }
                }
            }
        }

    };

    //window.UloopCustomSlider2 = new UloopCustomSlider();
})( jQuery );


/*

 slider: ,
 wrap_big_image: '.small_main_image',
 wrap_preview: '.small_preview_wrap',
 item_preview: 'ul li',{start_auto_on:'.wrap_post', slider: '.custom_post_image' }
 */
